<template>
	<div class="card">
		<div v-if="loading">
			<div class="d-flex flex-column justify-content-center align-items-center">
				<div class="spinner-border" role="status"></div>
				<p class="text-center mt-2"><strong>Loading...</strong></p>
			</div>
		</div>
		<template v-else>
			<div
				class="card-header d-flex justify-content-between align-items-center"
				>
				<h3 class="card-header-title text-sm">Partner Earnings</h3>
			</div>
			<div class="card-body">
				<!--    <div
        v-if="loadingUserWallet"
        class="d-flex justify-content-center align-items-center"
    >
      <div class="spinner-border" role="status"></div>
    </div>-->
				<template>
					<div class="row g-0 align-items-center justify-content-center">
						<div class="col-auto">
							<h1 class="display-3 mb-0">{{ partnersEarnings }}</h1>
						</div>
					</div>
					<div class="h5 mt-1 text-uppercase text-center text-muted mb-5">
						Partner Earnings
					</div>
					<div class="border-top border-muted">
						<!-- <div
          v-if="numberOfTrips && numberOfTrips >= 0"
          class="border-top border-muted"
        > -->
						<div class="row py-3">
							<div class="col text-muted">Total no. of trips</div>
							<div class="col-auto">{{ numberOfTrips }}</div>
						</div>
					</div>
					<div class="border-top border-muted">
						<!-- <div
          v-if="numberOfTrips && numberOfTrips >= 0"
          class="border-top border-muted"
        > -->

						<div class="row pt-3">
							<div class="col text-muted">Payout Period</div>
							<!-- <p v-if="remitPeriod.length == 0" class="col-auto font-weight-bold"> N/A </p> -->
							<p  class="col-auto font-weight-bold">Day {{ remitPeriod?.length ? remitPeriod[0]?.dayOfMonth : 'N/A' }} of every Month</p>
						</div>
					</div>
					<div class="border-top border-muted">
						<div class="row pt-3">
							<div class="col text-muted">Past Payouts</div>
							<router-link :to="`/pastpayout/${this.partnerData?.id}`" class="col-auto pat-link">View all</router-link>
						</div>
					</div>
				</template>
			</div>
		</template>
	</div>
</template>

<script>
export default {
  name: 'PartnerEarnings',
  props: {
    numberOfTrips: {
      type: [Number, String],
      default: null
    },
    totalEarnings: {
      type: [Number, String],
      default: null
    },
    partnerData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      range: [],
      remitPeriod: null,
      loading: false,
      partnerSid: this.$route.params.accountSid

    }
  },
  computed: {
    partnersEarnings () {
      return !this.totalEarnings
        ? '₦0.00'
        : `₦${parseFloat(this.totalEarnings)
            .toFixed(2)
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
    }
  },
  methods: {
    async getRemintancePeriod () {
      this.loading = true
      try {
        const res = await this.axios.get(`cost-revenue/v1/earnings-remittance-period?partnerId=${this.partnerSid}`)
        this.remitPeriod = res.data.result
      } catch (err) {
        
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    this.getRemintancePeriod()
  }
}
</script>

<style lang="scss" scoped>
.pat-link {
  color: #4848ed;
  text-decoration: underline;
}
</style>
